import React, { Component } from "react"

import { navigate } from "gatsby"
import Cookies from "js-cookie"
import Styles from "./signup-form-simple.module.scss"

const blockedEmailDomains = ["gmail.com", "yahoo.com", "hotmail.com", "outlook.com", "mail.ru", "aol.com", "protonmail.com", "icloud.com", "zoho.com", "yandex.com", "gmx.com", "tutanota.com", "live.com", "msn.com", "me.com", "inbox.com", "mail.com", "comcast.net", "proton.me", "foxmail.com", "qq.com", "ymail.com", "163.com", "126.com", "rediffmail.com", "fastmail.com"]

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.ContactForm = React.createRef()

    let campaignURL = Cookies.get("__gtm_campaign_url")
    let email = Cookies.get("email")
    let team_name = Cookies.get("team_name")
    this.state = {
      campaignURL: campaignURL,
      email: email,
      team_name: team_name,
    }
  }
  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = this.ContactForm.current
    const fieldsToSubmit = this.state
    const { email } = this.state

    if (window.trackRoarEvent) {
      window.trackRoarEvent(email)
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        ...fieldsToSubmit,
      }),
    })
      .then(response => {
        navigate(form.getAttribute("action"))
      })
      .catch(error => {
        console.log(`error in submiting the form data:${error}`)
      })
  }
  render() {
    const { email, company, jobTitle, name, surname } = this.state
    const { formName, formAction = "/registration-confirmation" } = this.props
    const emailDomain = email ? email.split("@")[1] : ""
    let invalidEmail = blockedEmailDomains.includes(emailDomain)
    let canSubmit = (email && company && jobTitle && name && surname && !invalidEmail) || false

    return (
      <form
        className={Styles.form}
        name={formName}
        method="post"
        action={formAction}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        ref={this.ContactForm}
      >
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label htmlFor={"bot-field"}>
            Don’t fill this out:{" "}
            <input
              name="bot-field"
              onChange={this.handleChange}
              className={Styles.input}
            />
          </label>
        </div>
        <div hidden>
          <input
            name="campaignURL"
            onChange={this.handleChange}
            value={this.state.campaignURL}
          />
        </div>
        <div className={Styles.inputRow}>
          <label htmlFor={"email"}>
            Business email address*
            <br />
            <input
              placeholder={"Please enter your email address"}
              required={true}
              type="email"
              name="email"
              onChange={this.handleChange}
              className={Styles.input}
            />
          </label>
        </div>
        {invalidEmail && <p className={Styles.emailError}>This form doesn't accept personal email addresses, please use your company email address, all emails will be securely stored and you will have the option to opt-in to marketing emails.</p>}
        <div className={Styles.inputRow}>
          <label htmlFor={"name"}>
            First name*
            <br />
            <input
              required
              placeholder={"Please enter your first name"}
              type="text"
              name="name"
              onChange={this.handleChange}
              className={Styles.input}
            />
          </label>
        </div>
        <div className={Styles.inputRow}>
          <label htmlFor={"surname"}>
            Last name*
            <br />
            <input
              required
              placeholder={"Please enter your last name"}
              type="text"
              name="surname"
              onChange={this.handleChange}
              className={Styles.input}
            />
          </label>
        </div>
        <div className={Styles.inputRow}>
          <label htmlFor={"jobTitle"}>
            Job Title*
            <br />
            <input
              required
              placeholder={"Please enter your job title"}
              type="text"
              name="jobTitle"
              onChange={this.handleChange}
              className={Styles.input}
            />
          </label>
        </div>
        <div className={Styles.inputRow}>
          <label htmlFor={"company"}>
            Company name*
            <br />
            <input
              required
              placeholder={"Please enter your company name"}
              type="text"
              name="company"
              onChange={this.handleChange}
              className={Styles.input}
            />
          </label>
        </div>

        <p>
          <button
            className={Styles.inputSubmit}
            disabled={!canSubmit}
            type="submit"
          >
            Register now
          </button>
        </p>
      </form>
    )
  }
}
export default ContactForm
