import React, { Component } from "react"
import Select from "react-select"
import { navigate } from "gatsby"
import omit from "lodash.omit"
import Cookies from "js-cookie"

import "react-phone-input-2/lib/style.css"
import Styles from "./signup-form.module.scss"
const blockedEmailDomains = ["gmail.com", "yahoo.com", "hotmail.com", "outlook.com", "mail.ru", "aol.com", "protonmail.com", "icloud.com", "zoho.com", "yandex.com", "gmx.com", "tutanota.com", "live.com", "msn.com", "me.com", "inbox.com", "mail.com", "comcast.net", "proton.me", "foxmail.com", "qq.com", "ymail.com", "163.com", "126.com", "rediffmail.com", "fastmail.com"]

const booking = {
  james: "https://meetings.hubspot.com/james245",
  tom: "https://meetings.hubspot.com/tombridges/60-45-30-min-meeting",
  chris: "https://meetings.hubspot.com/christopher-sansom",
  olivia: "https://meetings.hubspot.com/olivia40",
}

const industryOptions = [
  { value: "technology", label: "Technology" },
  { value: "retail", label: "Retail" },
  { value: "legal services", label: "Legal services" },
  { value: "financial services", label: "Financial services" },
  { value: "gaming", label: "Gaming" },
  { value: "media & entertainment", label: "Media & entertainment" },
  { value: "manufacturing", label: "Manufacturing" },
  { value: "faith based", label: "Faith based" },
  { value: "language vendor", label: "Language vendor" },
  { value: "other", label: "Other" },
]

const redirectUrls = {
  "technology": booking.tom,
  "retail": booking.chris,
  "legal services": booking.chris,
  "financial services": booking.tom,
  "gaming": booking.james,
  "media & entertainment": booking.chris,
  "manufacturing": booking.james,
  "faith based": booking.olivia,
  "language vendor": booking.olivia,
  "other": booking.james
}

const sizeOptions = [
  { value: "up to 200", label: "Up to 200" },
  { value: "more than 200", label: "More than 200" },
]

const selectCustomStyles = {
  control: provided => ({
    ...provided,
    height: 50,
  }),
  placeholder: provided => ({
    color: "rgba(0, 0, 0, 0.3)",
  }),
}

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.ContactForm = React.createRef()

    let campaignURL = Cookies.get("__gtm_campaign_url")
    let email = Cookies.get("email")
    let team_name = Cookies.get("team_name")

    this.state = {
      campaignURL: campaignURL,
      email: email || "",
      company: team_name,
    }
  }
  handleSelectChange = selectedOption => {
    this.setState({
      [selectedOption.name]: selectedOption,
    })
  }
  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = this.ContactForm.current
    const {
      email,
      campaignURL,
      industry,
      size,
      company,
    } = this.state

    if (window.trackRoarEvent) {
      window.trackRoarEvent(email)
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        campaignURL: campaignURL,
        email: email,
        industry: industry?.value,
        size: size?.value,
        company: company,
      }),
    })
      .then(response => {
        if (size.value == "up to 200") {
          navigate(booking.olivia)
        } else {
          const redirectUrl = redirectUrls[industry.value]
          navigate(redirectUrl)
        }
      })
      .catch(error => {
        console.log(`error in submiting the form data:${error}`)
      })
  }
  render() {
    const { industry, size, email, company } = this.state
    const emailDomain = email.split("@")[1]
    let invalidEmail = blockedEmailDomains.includes(emailDomain)
    let canSubmit = (email && company && size && !invalidEmail) || false

    return (
      <form
        className={Styles.form}
        name={this.props.formName}
        method="post"
        action="/contact-confirmation"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        id="signup-form"
        onSubmit={this.handleSubmit}
        ref={this.ContactForm}
      >
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label htmlFor={"bot-field"}>
            Don’t fill this out:{" "}
            <input
              name="bot-field"
              onChange={this.handleChange}
              className={Styles.input}
            />
          </label>
        </div>
        <div hidden>
          <input
            name="campaignURL"
            onChange={this.handleChange}
            value={this.state.campaignURL}
          />
        </div>
        <div className={Styles.inputRow}>
          <label htmlFor={"email"}>
            Work email address*
            <br />
            <input
              placeholder={"Please enter your email address"}
              required={true}
              type="email"
              name="email"
              value={email}
              onChange={this.handleChange}
              className={Styles.input}
            />
          </label>
          {invalidEmail && <p className={Styles.emailError}>This form doesn't accept personal email addresses, please use your company email address, all emails will be securely stored and you will have the option to opt-in to marketing emails.</p>}
        </div>
        <div className={Styles.inputRow}>
          <label htmlFor={"company"}>
            Company name*
            <br />
            <input
              required={true}
              placeholder={"Please enter your company name"}
              type="text"
              name="company"
              value={this.state.company}
              onChange={this.handleChange}
              className={Styles.input}
            />
          </label>
        </div>
        <div className={Styles.inputRow}>
          <label htmlFor={"size"}>
            How many employees do you have?*
            <br />
            <Select
              placeholder={"Pick from the list"}
              name={"size"}
              styles={selectCustomStyles}
              className={Styles.select}
              value={size}
              onChange={option => {
                this.handleSelectChange({ ...option, name: "size" })
              }}
              options={sizeOptions}
            />
          </label>
        </div>
        <div className={Styles.inputRow}>
          <label htmlFor={"industry"}>
            Which industry best describes your company?
            <br />
            <Select
              placeholder={"Pick from the list"}
              name={"industry"}
              styles={selectCustomStyles}
              className={Styles.select}
              value={industry}
              onChange={option => {
                this.handleSelectChange({ ...option, name: "industry" })
              }}
              options={industryOptions}
            />
          </label>
        </div>
        <p>
          <button
            className={Styles.inputSubmit}
            disabled={!canSubmit}
            type="submit"
          >
            Submit
          </button>
        </p>
      </form>
    )
  }
}
export default ContactForm
