import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import AltGrid from "./alternate-grid/alternate-grid"
import Checklist from "./checklist/checklist"
import Demo from "./demo/demo"
import Download from "./download/download"
import Features from "./features/features"
import FeaturesWithGraphics from "./features-with-graphics/features-with-graphics"
import Hero from "./hero/hero"
import HeroGrid from "./hero-grid/hero-grid"
import GraphicHero from "./graphic-hero/graphic-hero"
import Logos from "./logos/logos"
import LogoCarousel from "./logo-carousel/logo-carousel"
import Overview from "./overview/overview"
import Partners from "./partners/partners"
import Results from "./results/results"
import SubNavigation from "./sub-navigation/sub-navigation"
import Stacks from "./stacks/stacks"
import Steps from "./steps/steps"
import Tabs from "./tabs/tabs"
import TabsTrio from "./tabs-trio/tabs-trio"
import Testimonials from "./testimonials/testimonials"
import TestimonialsAlternative from "./testimonials-alternative/testimonials-alternative"
import Text from "./text/text"
import DemoSignup from "./demo-signup/demo-signup"
import Contact from "./contact/contact"
import Registration from "./registration/registration"
import Faqs from "./faqs/faqs"
import StatCards from "./stat-cards/stat-cards"
import TextSummaryWithQuote from "./text-summary-with-quote/text-summary-with-quote"
import HeadingAndCtaRow from "./heading-and-cta-row/heading-and-cta-row"
import ClientDescription from "./client-description/client-description"
import Pricing from "./pricing/pricing"
import Snackbar from "./snackbar/snackbar"
import TechSpec from "./tech-spec/tech-spec"
import Styles from "./slice.module.scss"
import DemoSignupSimple from "./demo-signup-simple/demo-signup-simple"
import InlineUpgradeForm from "./inline-upgrade-form/inline-upgrade-form"
import InlineUpgradeFormFull from "./inline-upgrade-form-full/inline-upgrade-form-full"
import FullWidthImage from "./full-width-image/full-width-image"
import Speakers from "./speakers/speakers"
import StaticSectionFeatureSummary from "../components/static-section-feature-summary/static-section-feature-summary"
import StaticSectionSocialProof from "../components/static-section-social-proof/static-section-social-proof"
import StaticSectionFeatureDetailed from "../components/static-section-feature-detailed/static-section-feature-detailed"
import StaticSectionClients from "../components/static-section-clients/static-section-clients"
import StaticBuiltFor from "../components/static-section-built-for/static-section-built-for"
import StaticFinal from "../components/static-section-final/static-section-final"

const Slices = ({ slices, pages, uid }) => {
  if (!slices) {
    return null
  }

  const content = slices.map((slice, index) => {
    switch (slice.type) {
      case "speakers":
        return (
          <section key={index} className={`${Styles.slice__speakers}`}>
            {<Speakers slice={slice} />}
          </section>
        )
      case "alternate_grid":
        return (
          <section key={index} className={`${Styles.slice__alternateGrid}`}>
            {<AltGrid slice={slice} />}
          </section>
        )
      case "checklist":
        return (
          <section key={index} className={`${Styles.slice__checklist}`}>
            {<Checklist slice={slice} />}
          </section>
        )
      case "demo":
        return (
          <section key={index} className={`${Styles.slice__demo}`}>
            {<Demo slice={slice} />}
          </section>
        )
      case "download":
        return (
          <section key={index} className={`${Styles.slice__download}`}>
            {<Download slice={slice} uid={uid} />}
          </section>
        )
      case "features":
        return (
          <section key={index} className={`${Styles.slice__features}`}>
            {<Features slice={slice} />}
          </section>
        )
      case "features_with_graphics":
        return (
          <section
            key={index}
            className={`${Styles.slice__featuresWithGraphics}`}
          >
            {<FeaturesWithGraphics slice={slice} />}
          </section>
        )
      case "hero":
        const hasSubPages = pages

        return (
          <section
            key={index}
            className={cn(
              Styles["slice__hero"],
              hasSubPages && Styles.slice__heroHasSubPages
            )}
          >
            {<Hero slice={slice} />}
          </section>
        )
      case "hero_grid":
        const hasLogoCarousel = slices[1].type === "logos_1"

        return (
          <section
            key={index}
            className={`${Styles.slice__hero}`}
          >
            {<div className={Styles.gridHeroContainer}>
              <HeroGrid slice={slice} />
              {hasLogoCarousel && <LogoCarousel slice={slices[1]} />}
              <StaticSectionFeatureSummary />

              <div style={{ backgroundColor: "#000" }}>
                <StaticSectionFeatureDetailed />
              </div>
              <div style={{ backgroundColor: "#000" }}>
                <StaticSectionClients />
                <StaticBuiltFor />
                <StaticFinal />
              </div>
              <StaticSectionSocialProof />
            </div>}
          </section>
        )
      case "logos_1":
        const hasHeroBeforeCarousel = slices[0].type === "hero_grid"

        if (hasHeroBeforeCarousel) return null

        return (
          <section key={index} className={`${Styles.slice__logos}`}>
            {<LogoCarousel slice={slice} />}
          </section>
        )
      case "logos":
        return (
          <section key={index} className={`${Styles.slice__logos}`}>
            {<Logos slice={slice} />}
          </section>
        )
      case "navigation":
        return (
          <section key={index} className={`${Styles.slice__navigation}`}>
            {<SubNavigation slice={slice} pages={pages} />}
          </section>
        )
      case "overview":
        const isHero = slices[0].type === "navigation" && index === 1

        return (
          <section key={index} className={`${Styles.slice__overview}`}>
            {<Overview slice={slice} isHero={isHero} />}
          </section>
        )
      case "heading_and_cta_row":
        return (
          <section key={index} className={`${Styles.slice__headingAndCtaRow}`}>
            {<HeadingAndCtaRow slice={slice} />}
          </section>
        )

      case "partners":
        return (
          <section key={index} className={`${Styles.slice__partners}`}>
            {<Partners slice={slice} />}
          </section>
        )
      case "stacks":
        return (
          <section key={index} className={`${Styles.slice__stacks}`}>
            {<Stacks slice={slice} />}
          </section>
        )
      case "steps":
        return (
          <section key={index} className={`${Styles.slice__steps}`}>
            {<Steps slice={slice} />}
          </section>
        )
      case "tabs":
        return (
          <section key={index} className={`${Styles.slice__tabs}`}>
            {<Tabs slice={slice} />}
          </section>
        )
      case "tabs_trio":
        return (
          <section key={index} className={`${Styles.slice__tabs_trio}`}>
            {<TabsTrio slice={slice} />}
          </section>
        )
      case "testimonials":
        return (
          <section key={index} className={`${Styles.slice__testimonials}`}>
            {<Testimonials slice={slice} />}
          </section>
        )
      case "testimonials_alternative":
        return (
          <section
            key={index}
            className={`${Styles.slice__testimonialsAlternative}`}
          >
            {<TestimonialsAlternative slice={slice} />}
          </section>
        )
      case "text":
        return (
          <section key={index} className={`${Styles.slice__text}`}>
            {<Text slice={slice} />}
          </section>
        )
      case "results":
        return (
          <section key={index} className={`${Styles.slice__results}`}>
            {<Results slice={slice} />}
          </section>
        )
      case "demo_signup":
        return (
          <section key={index} className={`${Styles.slice__demoSignup}`}>
            {<DemoSignup slice={slice} benefitList={slices[1].fields} />}
          </section>
        )
      case "demo_signup_simple":
        return (
          <section key={index} className={`${Styles.slice__demoSignup}`}>
            {<DemoSignupSimple slice={slice} benefitList={slices[1].fields} />}
          </section>
        )
      case "contact":
        return (
          <section key={index} className={`${Styles.slice__contact}`}>
            {<Contact slice={slice} />}
          </section>
        )
      case "registration":
        return (
          <section key={index} className={`${Styles.slice__registration}`}>
            {<Registration slice={slice} />}
          </section>
        )
      case "faqs":
        return (
          <section key={index} className={`${Styles.slice__faqs}`}>
            {<Faqs slice={slice} />}
          </section>
        )
      case "stat_cards":
        return (
          <section key={index} className={`${Styles.slice__statCards}`}>
            {<StatCards slice={slice} />}
          </section>
        )
      case "graphic_hero":
        return (
          <section key={index} className={`${Styles.slice__graphicHero}`}>
            {<GraphicHero slice={slice} />}
          </section>
        )
      case "text_summary_with_quote":
        return (
          <section
            key={index}
            className={`${Styles.slice__textSummaryWithQuote}`}
          >
            {<TextSummaryWithQuote slice={slice} />}
          </section>
        )
      case "client_description":
        return (
          <section key={index} className={`${Styles.slice__clientDescription}`}>
            {<ClientDescription slice={slice} />}
          </section>
        )
      case "pricing":
        return (
          <section key={index} className={`${Styles.slice__pricing}`}>
            {<Pricing slice={slice} />}
          </section>
        )
      case "snackbar":
        return <section key={index}>{<Snackbar slice={slice} />}</section>
      case "tech_spec":
        return (
          <section key={index} className={`${Styles.slice__techSpec}`}>
            <TechSpec slice={slice} />
          </section>
        )
      case "inline_upgrade_form":
        return (
          <section key={index} className={`${Styles.slice__demoSignup}`}>
            <InlineUpgradeForm slice={slice} />
          </section>
        )
      case "inline_upgrade_form_full":
        return (
          <section key={index} className={`${Styles.slice__demoSignup}`}>
            <InlineUpgradeFormFull slice={slice} />
          </section>
        )
      case "full_width_image":
        return (
          <section key={index} className={`${Styles.slice__fullWidthImage}`}>
            <FullWidthImage slice={slice} />
          </section>
        )
      default:
        return null
    }
  })

  const forceSetionFinalAboveFooterPages = ['pricing-tiers', 'partners']
  const forceSetionClientsAboveFooterPages = ['clients']

  return [...content,
  forceSetionFinalAboveFooterPages.includes(uid) && <StaticFinal />,
  forceSetionClientsAboveFooterPages.includes(uid) && <StaticSectionClients />
  ]
}

Slices.propTypes = {
  slices: PropTypes.array,
}

Slices.defaultProps = {
  slices: [],
}

export default Slices
